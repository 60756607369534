import { Player } from "./player";

export class Stat {
    player: Player;
    name: string;
    nbPoint: number;
    time: number;
    message: string;
    position: { x: number, y: number};

   
    constructor(pPlayer: Player, pNbPoint: number, pName: string, pTime: number, pMessage = "", pPos: { x: number, y: number} = { x: 0, y: 0}) {
      this.player = pPlayer;
      this.nbPoint = pNbPoint;
      this.name = pName;
      this.time = pTime;
      this.message = pMessage;
      this.position = pPos;
    }
  }