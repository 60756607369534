<template>
  <div style="width: 100%; height: 100%" ref="artRef"></div>
</template>

<script>
import Artplayer from "artplayer";

export default {
  name: "VideoPlayer",
  data() {
    return {
      instance: null,
    };
  },
  props: {
    src: String,
    time: Number,
  },
  mounted() {
    this.instance = new Artplayer({
      container: this.$refs.artRef,
      url: this.src ?? "",
      title: "Your Name",
      volume: 0.2,
      isLive: false,
      muted: false,
      autoplay: false,
      pip: true,
      screenshot: true,
      setting: true,
      loop: true,
      flip: true,
      playbackRate: true,
      aspectRatio: true,
      fullscreen: true,
      fullscreenWeb: true,
      subtitleOffset: true,
      miniProgressBar: true,
      mutex: true,
      backdrop: true,
      playsInline: true,
      autoPlayback: true,
      airplay: true,
      theme: "#23ade5",
      lang: navigator.language.toLowerCase(),
      whitelist: ["*"],
      moreVideoAttr: {
        crossOrigin: "anonymous",
      },

      contextmenu: [
        {
          html: "Custom menu",
          click: function (contextmenu) {
            console.info("You clicked on the custom menu");
            contextmenu.show = false;
          },
        },
      ],
      controls: [
        {
          position: "right",
          html: "Control",
          tooltip: "Control Tooltip",
          click: function () {
            console.info("You clicked on the custom control");
          },
        },
      ],
    });
    this.$nextTick(() => {
      this.$emit("get-instance", this.instance);
    });

    this.instance.on("ready", () => {
      this.instance.seek = this.time;
    });
  },
  methods: {
    getCurrentTime() {
      return this.instance.currentTime;
    },
    pause() {
      return this.instance.pause();
    },
    play() {
      return this.instance.play();
    },
  },
  beforeUnmount() {
    if (this.instance && this.instance.destroy) {
      this.instance.destroy(false);
    }
  },
};
</script>
