import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d61ede1e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  color: "medium",
                  onClick: _ctx.cancel
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: _ctx.closeOutline }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode("Nouveau match")
              ]),
              _: 1
            }),
            _createVNode(_component_ion_buttons, { slot: "end" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, { onClick: _ctx.saveGame }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: _ctx.checkmarkOutline }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_list, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Match à domicile")
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_toggle, {
                  slot: "end",
                  checked: _ctx.domicile
                }, null, 8, ["checked"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Equipe adverse")
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_input, {
                  value: _ctx.nomEquipeAdverse,
                  onIonInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.nomEquipeAdverse = $event.target.value)),
                  placeholder: "villeurbanne, Nets, Lakers, Folclo ..."
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Division")
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_input, {
                  value: _ctx.division,
                  onIonInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.division = $event.target.value)),
                  type: "text",
                  placeholder: "Pro A, National 3, Regional 2 .."
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Logo equipe adverse")
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_input, {
                  onChange: _ctx.changeImg,
                  accept: "image/*",
                  type: "file",
                  id: "imgInp"
                }, null, 8, ["onChange"]),
                _createElementVNode("img", {
                  class: _normalizeClass({ hide: _ctx.logo === '' }),
                  src: _ctx.logo,
                  width: "100",
                  height: "100",
                  alt: "your image"
                }, null, 10, _hoisted_1)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Source video")
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_input, {
                  value: _ctx.video,
                  onIonInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.video = $event.target.value)),
                  type: "text",
                  placeholder: "www.folclomedia.....mp4"
                }, null, 8, ["value"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}