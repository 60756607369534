import { Game } from '@/models/game';
import { createStore } from 'vuex';
import { Storage } from '@ionic/storage';

const storage = new Storage();
storage.create();


const store = createStore({
    state: {
        games : [ ] as Game[]
    },
    getters:{
        getGames : async (state) => {
            const myAwesomeValue = await storage.get('games')
            if(myAwesomeValue != ''){
                state.games = myAwesomeValue;
            }
            return state.games;
        },
        /*getGame : async (state, id) => {
            const myAwesomeValue = await storage.get('games')
            if(myAwesomeValue != ''){
                state.games = myAwesomeValue;
            }
            return state.games;
        }*/
    }
  });

  export default store