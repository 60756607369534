
import { defineComponent } from "vue";
import {
  IonText,
  IonButtons,
  IonTitle,
  IonCol,
  IonGrid,
  IonContent,
  IonRow,
  IonHeader,
  IonToolbar,
  IonButton,
  IonThumbnail,
  IonIcon,
  modalController,
} from "@ionic/vue";

import { checkmarkOutline, closeOutline, reloadOutline } from "ionicons/icons";
import { Game } from "@/models/game";
import { Player } from "@/models/player";
export default defineComponent({
  name: "SubVue",
  props: {
    game: Game,
  },
  data: () => ({
    selectedPlayerTeam1: [] as Player[],
    selectedPlayerTeam2: [] as Player[],
  }),
  components: {
    IonCol,
    IonGrid,
    IonContent,
    IonThumbnail,
    IonButton,
    IonIcon,
    IonText,
    IonButtons,
    IonTitle,
    IonRow,
    IonHeader,
    IonToolbar,
    //IonMenuToggle,
    //IonIcon,
  },
  methods: {
    selectPlayer(equipeDomicile: boolean, player: Player) {
      if (equipeDomicile) {
        if (this.selectedPlayerTeam1.includes(player)) {
          this.selectedPlayerTeam1.splice(
            this.selectedPlayerTeam1.indexOf(player),
            1
          );
        } else {
          this.selectedPlayerTeam1.push(player);
        }
      } else {
        if (this.selectedPlayerTeam2.includes(player)) {
          this.selectedPlayerTeam2.splice(
            this.selectedPlayerTeam2.indexOf(player),
            1
          );
        } else {
          this.selectedPlayerTeam2.push(player);
        }
      }
    },
    cancel() {
      return modalController.dismiss(null, "cancel");
    },
    remplacement() {
      if (
        (this.game ?? ({} as Game)).team1.players.filter((p) => p.onField)
          .length +
          this.selectedPlayerTeam1.filter((p) => !p.onField).length -
          this.selectedPlayerTeam1.filter((p) => p.onField).length ===
        5
      ) {
        for (let player of this.selectedPlayerTeam1) {
          player.onField = !player.onField;
        }

        for (let player of this.selectedPlayerTeam2) {
          player.onField = !player.onField;
        }
        this.selectedPlayerTeam1 = [];
        this.selectedPlayerTeam2 = [];
        return modalController.dismiss(null, "confirm");
      }
    },
  },
  setup() {
    return {
      checkmarkOutline,
      closeOutline,
      reloadOutline,
    };
  },
});
