import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import HomePage from '../views/HomePage.vue'
import MatchPage from '../views/MatchPage.vue';
import TeamPage from '../views/TeamPage.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: HomePage
  }
  ,
  {
    path: '/match/:id',
    name: 'Match',
    component: MatchPage,
    beforeEnter: (to, from, next) => {
      console.log(to.name, from.name);
      console.debug('before enter POV');
      next();
  }
  },
  {
    path: '/team/:id',
    name: 'Team',
    component: TeamPage
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
