import { Game } from '@/models/game';
import { Stat } from '@/models/stat';
import storage from '@/storage';
export async function saveGame(game: Game) {
  const games = (await storage.get("games")) as Game[];
  games[games.map((g) => g.id).indexOf(game.id)] = game;
  await storage.set("games", JSON.parse(JSON.stringify(games)));
}


export async function getGame(id: string) {
  const games = (await storage.get("games")) as Game[];
  return games.find((g) => g.id === id) ?? ({} as Game);
}


export function  closeGame(game: Game) {
  game.status = 1;
  saveGame(game);
}

export async function deleteGame(id: string) {
  const allGames = (await storage.get("games")) as Game[];
  allGames.splice(allGames.map((g) => g.id).indexOf(id), 1);
  await storage.set("games", JSON.parse(JSON.stringify(allGames)));
}

export async function getGames(idTeam: string) : Promise<Game[]> {
  const allGames = (await storage.get("games")) as Game[];
  let games =  [] as Game[]
  if (!allGames) {
    await storage.set("games", []);
    games = [] as Game[];
  } else {
    games = allGames.filter(
      (g) => g.team1.id === idTeam || g.team2.id === idTeam
    );
  }
  return games;
}

export function exportStat(stats: Stat[]) {
  const dataStr =
    "data:text/json;charset=utf-8," +
    encodeURIComponent(
      JSON.stringify(
        stats.map((s) => ({
          name: s.name,
          nbPoint: s.nbPoint,
          message: s.message,
          time: s.time,
          player: {
            id: s.player?.id,
            name: s.player?.name,
            num: s.player?.num
          }
        }))
      )
    );
  const downloadAnchorNode = document.createElement("a");
  downloadAnchorNode.setAttribute("href", dataStr);
  downloadAnchorNode.setAttribute("download", "stats.json");
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}



