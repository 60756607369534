import { Team } from '@/models/team';
import storage from '@/storage';

export async function addTeam(team: Team) {
  const teams = (await storage.get("teams")) as Team[];
  teams.push(team);
  await storage.set("teams", teams);
}

export async function getTeam(id: string) {
  const teams = (await storage.get("teams")) as Team[];
  return teams.find((t) => t.id === id) ?? ({} as Team);
}

export async function deleteTeam(id: string) {
  const teams = (await storage.get("teams")) as Team[];
  teams.splice(teams.map((t) => t.id).indexOf(id), 1);
  await storage.set("teams", JSON.parse(JSON.stringify(teams)));
}

export async function getTeams() : Promise<Team[]> {
  const teams = (await storage.get("teams")) ?? [] as Team[];

    if (teams.length === 0) {
      await storage.set("teams", []);
    }
  return teams;
}
