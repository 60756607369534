import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_menu_toggle = _resolveComponent("ion-menu-toggle")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_menu = _resolveComponent("ion-menu")!
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_avatar = _resolveComponent("ion-avatar")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_modal = _resolveComponent("ion-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_menu, { contentId: "match-menu" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_header, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_toolbar, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Menu")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_content, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_list, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_menu_toggle, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, {
                      onClick: _cache[0] || (_cache[0] = () => _ctx.$router.go(-1))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Quitter ")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_menu_toggle, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, { onClick: _ctx.saveGame }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Sauvgarder ")
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_menu_toggle, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, { onClick: _ctx.closeGame }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Terminer ")
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_menu_toggle, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, { onClick: _ctx.exportStat }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Exporter les stats ")
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_menu_toggle, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, {
                      id: "modal_stat",
                      onClick: _ctx.exportStatText
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Exporter les stats text")
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_header, {
      id: "match-menu",
      translucent: true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "end" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_menu_button)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_grid, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_col, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_text, { style: {"margin-right":"2vw"} }, {
                              default: _withCtx(() => [
                                _createElementVNode("sub", null, _toDisplayString(_ctx.game?.team1?.name.substring(0, 3).toUpperCase()), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_avatar, { class: "equipe-img" }, {
                              default: _withCtx(() => [
                                _createElementVNode("img", {
                                  alt: "Silhouette of a person's head",
                                  src: _ctx.game?.team1?.logo
                                }, null, 8, _hoisted_1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_col, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_text, { style: {"margin":"auto"} }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString((_ctx.game?.stats ?? [])
                    .filter(
                      (stat) =>
                        _ctx.game?.team1?.players.some(
                          (pl) => pl.id == stat.player.id
                        ) && ["2pts", "3pts", "1pt"].includes(stat.name)
                    )
                    .reduce((a, b) => a + b.nbPoint, 0)) + " - " + _toDisplayString((_ctx.game?.stats ?? [])
                    .filter(
                      (stat) =>
                        _ctx.game?.team2?.players.some(
                          (pl) => pl.id == stat.player.id
                        ) && ["2pts", "3pts", "1pt"].includes(stat.name)
                    )
                    .reduce((a, b) => a + b.nbPoint, 0)), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_col, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_avatar, { class: "equipe-img" }, {
                              default: _withCtx(() => [
                                _createElementVNode("img", {
                                  alt: "Silhouette of a person's head",
                                  src: _ctx.game?.team2?.logo
                                }, null, 8, _hoisted_2)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_text, { style: {"margin-left":"2vw"} }, {
                              default: _withCtx(() => [
                                _createElementVNode("sub", null, _toDisplayString(_ctx.game?.team2?.name.substring(0, 3).toUpperCase()), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_modal, {
      ref: "modal_stat",
      class: "modal_stat",
      id: "modal_stat",
      trigger: "modal_stat"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_header, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_toolbar, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_buttons, { slot: "start" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_button, {
                      color: "medium",
                      onClick: _ctx.fermer
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, { icon: _ctx.closeOutline }, null, 8, ["icon"])
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Copie ce qu'il y a dans le input et envoie le moi")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_content, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_textarea, {
              value: _ctx.statsoutput,
              class: "textRemarque",
              placeholder: "Type something here"
            }, null, 8, ["value"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 512)
  ], 64))
}