import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { style: {"display":"flex","margin-top":"1vh"} }
const _hoisted_2 = { style: {"display":"flex","position":"relative"} }
const _hoisted_3 = { style: {"margin":"auto"} }
const _hoisted_4 = { style: {"display":"flex","margin-top":"3vh"} }
const _hoisted_5 = { style: {"display":"flex","flex-wrap":"wrap"} }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "numero" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "numero" }
const _hoisted_11 = { style: {"display":"flex"} }
const _hoisted_12 = { style: {"display":"flex"} }
const _hoisted_13 = { style: {"display":"flex","margin-bottom":"10vh"} }
const _hoisted_14 = { style: {"display":"flex"} }
const _hoisted_15 = { style: {"display":"flex"} }
const _hoisted_16 = { style: {"margin":"auto"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_navbar_match = _resolveComponent("navbar-match")!
  const _component_video_player = _resolveComponent("video-player")!
  const _component_Youtube = _resolveComponent("Youtube")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_sub_vue = _resolveComponent("sub-vue")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_navbar_match, { game: _ctx.game }, null, 8, ["game"]),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_ctx.game.src && !_ctx.game.src.includes('youtu'))
              ? (_openBlock(), _createBlock(_component_video_player, {
                  key: 0,
                  ref: "videoplayer",
                  style: {"width":"100%","height":"30vh"},
                  src: _ctx.game.src,
                  time: Math.max(..._ctx.game.stats.map((s) => s.time))
                }, null, 8, ["src", "time"]))
              : _createCommentVNode("", true),
            _withDirectives(_createVNode(_component_Youtube, {
              style: {"margin":"auto"},
              src: _ctx.game.src,
              width: _ctx.windowWith - 5,
              resize: "true",
              onReady: _ctx.onReady,
              ref: "youtube"
            }, null, 8, ["src", "width", "onReady"]), [
              [_vShow, _ctx.game.src && _ctx.game.src.includes('youtu')]
            ])
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", {
              class: "historique",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.historiqueClick($event)))
            }, [
              _createElementVNode("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.game.stats ?? [] as Stat[])
            .filter((s) => s.name !== 'note')
            .slice(0, 3), (stat) => {
                  return (_openBlock(), _createElementBlock("div", {
                    style: {"display":"flex"},
                    key: stat
                  }, [
                    _createVNode(_component_ion_text, { color: "light" }, {
                      default: _withCtx(() => [
                        _createElementVNode("h3", null, _toDisplayString(stat.player.name) + " : " + _toDisplayString(stat.name) + " at " + _toDisplayString(_ctx.displayTime(stat.time)), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]))
                }), 128))
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_ion_button, {
              style: {"margin":"auto"},
              color: "danger",
              id: "open-modal"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Sub")
              ]),
              _: 1
            }),
            _createVNode(_component_ion_button, {
              onClick: _ctx.handleTimer,
              fill: _ctx.runningTimer ? 'outline' : 'solid',
              style: {"margin":"auto"},
              color: "success"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.displayTime(_ctx.time)), 1)
              ]),
              _: 1
            }, 8, ["onClick", "fill"]),
            _createVNode(_component_ion_button, {
              style: {"margin":"auto"},
              color: "success",
              onClick: _ctx.sethighlight
            }, {
              default: _withCtx(() => [
                _createTextVNode(" pts ")
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_ion_button, {
              style: {"margin":"auto"},
              color: "primary",
              id: "modal_note"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Note ")
              ]),
              _: 1
            }),
            _createVNode(_component_ion_button, {
              onClick: _ctx.cancelAction,
              fill: "outline",
              style: {"margin":"auto"},
              color: "dark"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, { icon: _ctx.returnUpBackOutline }, null, 8, ["icon"])
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.game.team1?.players.filter((p) => p.onField), (player) => {
              return (_openBlock(), _createElementBlock("div", {
                style: {"margin":"auto","text-align":"center","position":"relative"},
                key: player.id,
                onClick: ($event: any) => (
            _ctx.currentPlayer !== player
              ? (_ctx.currentPlayer = player)
              : (_ctx.currentPlayer = {} as Player)
          )
              }, [
                _createVNode(_component_ion_thumbnail, {
                  class: _normalizeClass([{
              disabled: _ctx.currentPlayer !== player,
            }, "imageplayer"])
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      alt: "",
                      src: player.picture
                    }, null, 8, _hoisted_7)
                  ]),
                  _: 2
                }, 1032, ["class"]),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_ion_text, { style: {"width":"50%","background-color":"rgba(0, 0, 0, 0.3)"} }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(player.num), 1)
                    ]),
                    _: 2
                  }, 1024)
                ])
              ], 8, _hoisted_6))
            }), 128)),
            _createElementVNode("div", {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (
            _ctx.currentPlayer !== _ctx.game.team2?.players[0]
              ? (_ctx.currentPlayer = _ctx.game.team2?.players[0])
              : (_ctx.currentPlayer = {} as Player)
          )),
              style: {"margin":"auto","text-align":"center","position":"relative"}
            }, [
              _createVNode(_component_ion_thumbnail, {
                class: _normalizeClass([{
              disabled: _ctx.currentPlayer !== _ctx.game.team2?.players[0],
            }, "imageplayer"])
              }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    alt: "",
                    src: _ctx.game.team2?.logo
                  }, null, 8, _hoisted_9)
                ]),
                _: 1
              }, 8, ["class"]),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_ion_text, { style: {"width":"50%","background-color":"rgba(0, 0, 0, 0.3)"} }, {
                  default: _withCtx(() => [
                    _createTextVNode(" AD ")
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_ion_row, { style: {"margin":"auto"} }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_col, { size: "auto" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_button, {
                      expand: "block",
                      color: "success",
                      disabled: !_ctx.currentPlayer.name,
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setAction('2pts', 2)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" 2pts ")
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_col, { size: "auto" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_button, {
                      expand: "block",
                      color: "success",
                      disabled: !_ctx.currentPlayer.name,
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setAction('3pts', 3)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" 3pts ")
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_col, { size: "auto" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_button, {
                      expand: "block",
                      color: "success",
                      disabled: !_ctx.currentPlayer.name,
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setAction('1pt', 1)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" 1pt ")
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_col, { size: "auto" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_button, {
                      expand: "block",
                      disabled: !_ctx.currentPlayer.name,
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.setAction('Rdd', 1)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Rdd ")
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_ion_row, { style: {"margin":"auto"} }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_col, { size: "auto" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_button, {
                      expand: "block",
                      color: "danger",
                      disabled: !_ctx.currentPlayer.name,
                      onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.setAction('-2pts', 1)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" 2pts ")
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_col, { size: "auto" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_button, {
                      expand: "block",
                      color: "danger",
                      disabled: !_ctx.currentPlayer.name,
                      onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.setAction('-3pts', 1)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" 3pts ")
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_col, { size: "auto" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_button, {
                      expand: "block",
                      color: "danger",
                      disabled: !_ctx.currentPlayer.name,
                      onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.setAction('-1pt', 1)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" 1pt ")
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_col, { size: "auto" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_button, {
                      expand: "block",
                      disabled: !_ctx.currentPlayer.name,
                      onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.setAction('Rdo', 1)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Rdo ")
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_ion_row, { style: {"margin":"auto"} }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_col, { size: "auto" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_button, {
                      color: "primary",
                      expand: "block",
                      disabled: !_ctx.currentPlayer.name,
                      onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.setAction('Ast', 1)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Ast ")
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_col, { size: "auto" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_button, {
                      color: "danger",
                      expand: "block",
                      disabled: !_ctx.currentPlayer.name,
                      onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.setAction('To', 1)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" To ")
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_col, { size: "auto" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_button, {
                      expand: "block",
                      disabled: !_ctx.currentPlayer.name,
                      onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.setAction('Stl', 1)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Stl ")
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_col, { size: "auto" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_button, {
                      expand: "block",
                      disabled: !_ctx.currentPlayer.name,
                      onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.setAction('Blk', 1)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Blk ")
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _createVNode(_component_ion_modal, {
            ref: "modal",
            trigger: "open-modal",
            onWillDismiss: _ctx.onWillDismiss
          }, {
            default: _withCtx(() => [
              _createVNode(_component_sub_vue, { game: _ctx.game }, null, 8, ["game"])
            ]),
            _: 1
          }, 8, ["onWillDismiss"])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_modal, {
        ref: "modal_note",
        class: "modal_note",
        id: "modal_note",
        trigger: "modal_note",
        onWillDismiss: _ctx.play,
        onWillPresent: _ctx.pause
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_header, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode(" Remarque ")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_content, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_ion_textarea, {
                  value: _ctx.currentNote,
                  onIonInput: _cache[14] || (_cache[14] = ($event: any) => (_ctx.currentNote = $event.target.value)),
                  class: "textRemarque",
                  placeholder: "Type something here"
                }, null, 8, ["value"])
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _createVNode(_component_ion_button, {
                    color: "danger",
                    onClick: _ctx.dismissNote
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Annuler")
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_ion_button, {
                    color: "success",
                    onClick: _ctx.setNote
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Valider")
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["onWillDismiss", "onWillPresent"])
    ]),
    _: 1
  }))
}