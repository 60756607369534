import { Stat } from "./stat";
import { Team } from "./team";

export class Game {
    id: string;
    team1: Team;
    team2: Team;
    stats: Stat[];
    status: number;
    src: string;

   
    constructor(pId: string, pTeam1: Team, pTeam2: Team, pStats: Stat[], psrc = "") {
      this.id = pId;
      this.team1 = pTeam1;
      this.team2 = pTeam2;
      this.stats = pStats;
      this.status = 0;
      this.src = psrc;
    }

  }