
import { defineComponent } from "vue";
import { saveGame, closeGame, exportStat } from "@/service/game";
import {
  IonContent,
  IonCol,
  IonRow,
  IonText,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonMenu,
  IonItem,
  IonList,
  IonGrid,
  IonAvatar,
  IonButtons,
  IonMenuButton,
  IonModal,
  IonMenuToggle,
} from "@ionic/vue";

import { closeOutline } from "ionicons/icons";
import { Game } from "@/models/game";

export default defineComponent({
  props: {
    game: Game,
  },
  name: "NavbarMatch",
  data: () => ({
    statsoutput: "",
  }),
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonMenu,
    IonItem,
    IonList,
    IonGrid,
    IonAvatar,
    IonButtons,
    IonMenuButton,
    IonCol,
    IonRow,
    IonText,
    IonMenuToggle,
    IonModal,
  },
  methods: {
    exportStat() {
      if (this.game) {
        exportStat(this.game.stats);
      }
    },
    saveGame() {
      if (this.game) {
        saveGame(this.game);
      }
    },
    fermer() {
      (this.$refs.modal_stat as any).$el.dismiss(null, "cancel");
    },
    exportStatText() {
      if (this.game) {
        this.statsoutput = JSON.stringify(
          this.game.stats.map((s) => ({
            name: s.name,
            nbPoint: s.nbPoint,
            message: s.message,
            time: s.time,
            player: {
              id: s.player?.id,
              name: s.player?.name,
              num: s.player?.num,
            },
          }))
        );
      }
    },
    closeGame() {
      if (this.game) {
        closeGame(this.game);
        this.$router.go(-1);
      }
    },
  },
  setup() {
    return {
      closeOutline,
    };
  },
});
