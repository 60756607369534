import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_avatar = _resolveComponent("ion-avatar")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_avatar, { slot: "start" }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: 
          _ctx.player?.picture
            ? _ctx.player?.picture
            : 'https://ionicframework.com/docs/img/demos/avatar.svg'
        
          }, null, 8, _hoisted_1)
        ]),
        _: 1
      }),
      _createVNode(_component_ion_label, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.player?.name), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.player?.num), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}