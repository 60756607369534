export class Player {
    id: string;
    picture: string;
    name: string;
    num: number;
    poste:string;
    onField: boolean;

   
    constructor(pId: string, pPicture: string, pName: string, pNum: number, pOnField = false, pPoste = 'G') {
      this.id = pId;
      this.picture = pPicture;
      this.name = pName;
      this.num = pNum;
      this.onField = pOnField;
      this.poste = pPoste;
    }
  }