
import { defineComponent } from "vue";
import {
  IonTitle,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonContent,
  IonButton,
  IonItem,
  IonList,
  IonLabel,
  IonInput,
  IonIcon,
  modalController,
} from "@ionic/vue";

import { checkmarkOutline, closeOutline } from "ionicons/icons";

import { Team } from "@/models/team";
import { addTeam } from "@/service/team";
import { v4 as uuidv4 } from "uuid";

export default defineComponent({
  name: "NewTeamVue",
  data: () => ({ previewImage: "", name: "", division: "" }),
  components: {
    IonTitle,
    IonContent,
    IonButton,
    IonItem,
    IonList,
    IonLabel,
    IonInput,
    IonIcon,
    IonHeader,
    IonToolbar,
    IonButtons,
    //IonMenuToggle,
    //IonIcon,
  },
  methods: {
    getBase64: (file: File) =>
      new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result?.toString() || "");
        reader.onerror = (error) => reject(error);
      }),
    changeImg(evt: Event) {
      const target = evt.target as HTMLInputElement;
      const file = target.files;
      if (file) {
        this.getBase64(file[0]).then((res) => (this.previewImage = res));
      }
    },
    cancel() {
      return modalController.dismiss(null, "cancel");
    },

    async saveTeam() {
      await addTeam(
        new Team(uuidv4(), this.previewImage, this.name, this.division, [])
      );
      modalController.dismiss(null, "saved");
    },
  },
  setup() {
    return {
      checkmarkOutline,
      closeOutline,
    };
  },
});
