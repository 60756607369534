import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-04efed91"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "container" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_item_option = _resolveComponent("ion-item-option")!
  const _component_ion_item_options = _resolveComponent("ion-item-options")!
  const _component_ion_item_sliding = _resolveComponent("ion-item-sliding")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_new_team_vue = _resolveComponent("new-team-vue")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Accueil")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createVNode(_component_ion_button, { id: "newTeam" }, {
              default: _withCtx(() => [
                _createTextVNode("nouveau")
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ion_list, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.teams, (team) => {
                  return (_openBlock(), _createBlock(_component_ion_item_sliding, {
                    key: team.id
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_item, {
                        "router-link": '/team/' + team.id
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_thumbnail, { slot: "start" }, {
                            default: _withCtx(() => [
                              _createElementVNode("img", {
                                alt: "Silhouette of mountains",
                                src: team.logo
                              }, null, 8, _hoisted_2)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(team.name), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1032, ["router-link"]),
                      _createVNode(_component_ion_item_options, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_item_option, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Favorite")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_item_option, {
                            color: "danger",
                            onClick: ($event: any) => (_ctx.deleteItem(team.id))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("Delete")
                            ]),
                            _: 2
                          }, 1032, ["onClick"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }),
            _createVNode(_component_ion_modal, {
              ref: "newTeam",
              trigger: "newTeam",
              onWillDismiss: _ctx.onWillDismiss
            }, {
              default: _withCtx(() => [
                _createVNode(_component_new_team_vue)
              ]),
              _: 1
            }, 8, ["onWillDismiss"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}