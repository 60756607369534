import { Player } from "./player";

export class Team {
    id: string;
    logo: string;
    name: string;
    division: string;
    players: Player[]; 

   
    constructor(pId: string, pLogo: string, pName: string, pDivision: string, pPlayers: Player[]) {
      this.id = pId;
      this.logo = pLogo;
      this.name = pName;
      this.players = pPlayers;
      this.division = pDivision;
    }
  }