
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { defineComponent } from "vue";
import { useMeta } from "vue-meta";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
  },
  setup() {
    useMeta({
      title: "",
      htmlAttrs: { lang: "fr", amp: true },
    });
  },
});
