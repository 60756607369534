
import PlayerItemVue from "@/components/PlayerItem.vue";
import NewPlayerVue from "@/components/NewPlayer.vue";
import NewGameVue from "@/components/NewGame.vue";
import storage from "@/storage";

import {
  IonBackButton,
  IonContent,
  IonLabel,
  IonSegmentButton,
  IonPage,
  IonList,
  IonButton,
  IonModal,
  IonItem,
  IonIcon,
  IonItemSliding,
  IonItemOption,
  IonItemOptions,
  IonSegment,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
} from "@ionic/vue";

import { trashOutline, downloadOutline, pencilOutline } from "ionicons/icons";
import { defineComponent } from "vue";
import { Game } from "@/models/game";
import { Team } from "@/models/team";
import { Player } from "@/models/player";
import { getGames, deleteGame, exportStat } from "@/service/game";
import { Stat } from "@/models/stat";

export default defineComponent({
  name: "TeamPage",
  data() {
    return {
      games: [] as Game[],
      team: {} as Team,
      page: "effectif",
      currentPlayer: {} as Player,
    };
  },
  components: {
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonContent,
    IonLabel,
    IonSegmentButton,
    IonBackButton,
    PlayerItemVue,
    IonPage,
    IonList,
    IonItem,
    IonSegment,
    IonButton,
    IonModal,
    NewPlayerVue,
    NewGameVue,
    IonIcon,
    IonItemSliding,
    IonItemOption,
    IonItemOptions,
  },
  async mounted() {
    const teamid = this.$route.params["id"];
    const teams = (await storage.get("teams")) as Team[];
    this.team = teams.find((t) => t.id === teamid) ?? ({} as Team);
    this.games = await getGames(this.team.id);
  },
  methods: {
    async deletePlayer(player: Player) {
      this.team.players.splice(this.team.players.indexOf(player), 1);
      const teams = (await storage.get("teams")) as Team[];
      teams[teams.map((t) => t.id).indexOf(this.team.id)] = this.team;
      await storage.set("teams", JSON.parse(JSON.stringify(teams)));
    },

    async deleteGame(id: string) {
      await deleteGame(id);

      this.games = await getGames(this.team.id);
    },

    async exportStat(stats: Stat[]) {
      exportStat(stats);
    },
    goToMatch(id: string) {
      this.$router.push(`/match/${id}`);
    },
    segmentChanged(e: CustomEvent) {
      console.log(e.detail);
      this.page = e.detail.value;
    },

    async onWillDismiss() {
      const allGames = (await storage.get("games")) as Game[];

      this.games = allGames.filter(
        (g) => g.team1.id === this.team.id || g.team2.id === this.team.id
      );
    },
  },
  setup() {
    return {
      trashOutline,
      downloadOutline,
      pencilOutline,
    };
  },
});
