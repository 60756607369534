
import NewTeamVue from "@/components/NewTeam.vue";
import { Team } from "@/models/team";
import { deleteTeam, getTeams } from "@/service/team";

import {
  IonToolbar,
  IonHeader,
  IonPage,
  IonContent,
  IonModal,
  IonItem,
  IonThumbnail,
  IonList,
  IonItemOptions,
  IonItemSliding,
  IonButton,
  IonItemOption,
  IonLabel,
  IonTitle,
} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "HomePage",
  data: () => {
    return {
      teams: [] as Team[],
    };
  },
  components: {
    IonToolbar,
    IonHeader,
    IonTitle,
    IonContent,
    IonPage,
    NewTeamVue,
    IonModal,
    IonItemSliding,
    IonButton,
    IonItem,
    IonThumbnail,
    IonList,
    IonLabel,
    IonItemOptions,
    IonItemOption,
  },
  async mounted() {
    this.teams = await getTeams();
  },

  methods: {
    async onWillDismiss() {
      this.teams = await getTeams();
    },
    async deleteItem(id: string) {
      await deleteTeam(id);
      this.teams = await getTeams();
    },
    goToTeam(id: string) {
      this.$router.push("/team/" + id);
    },
  },
});
