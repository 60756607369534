
import { defineComponent } from "vue";
import {
  IonTitle,
  IonToggle,
      IonContent,
    IonButton,
    IonItem,
    IonList,
    IonLabel,
    IonInput,
    IonIcon,
    IonHeader,
  IonToolbar,
  IonButtons,
  modalController,
} from "@ionic/vue";

import { checkmarkOutline, closeOutline } from "ionicons/icons";
import { v4 as uuidv4 } from "uuid";
import storage from "@/storage";
import { Team } from "@/models/team";
import { Game } from "@/models/game";
import { Player } from "@/models/player";

export default defineComponent({
  name: "NewGameVue",
  props: {
    team: Team,
  },
  data: () => ({
    logo: "",
    date: new Date(),
    domicile: true,
    division: "",
    nomEquipeAdverse: "",
    video:""
  }),
  components: {
  IonToggle,
    IonTitle,
        IonContent,
    IonButton,
    IonItem,
    IonList,
    IonLabel,
    IonInput,
    IonIcon,
    IonHeader,
  IonToolbar,
  IonButtons,
  },
  methods: {
    getBase64: (file: File) =>
      new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result?.toString() || "");
        reader.onerror = (error) => reject(error);
      }),
    changeImg(evt: Event) {
      const target = evt.target as HTMLInputElement;
      const file = target.files;
      if (file) {
        this.getBase64(file[0]).then((res) => (this.logo = res));
      }
    },
    cancel() {
      return modalController.dismiss(null, "cancel");
    },

    async saveGame() {
      const games = (await storage.get("games")) as Game[];

      const team1 = this.domicile
        ? this.team
        : new Team("adverse", this.logo, this.nomEquipeAdverse, this.division, [
            new Player("adversaire", this.logo, this.nomEquipeAdverse ?? "AD", 0, true),
          ]);

      const team2 = this.domicile
        ? new Team("adverse", this.logo, this.nomEquipeAdverse, this.division, [
            new Player("adversaire", this.logo, this.nomEquipeAdverse ?? "AD", 0, true),
          ])
        : this.team;

      games.push(
        new Game(uuidv4(), team1 ?? ({} as Team), team2 ?? ({} as Team), [], this.video)
      );

      await storage.set("games", JSON.parse(JSON.stringify(games)));

      modalController.dismiss(null, "saved");
    },
  },
  setup() {
    return {
      checkmarkOutline,
      closeOutline,
    };
  },
});
