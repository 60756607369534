import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-20e24399"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "box-border" }
const _hoisted_4 = { class: "border-white" }
const _hoisted_5 = { class: "img-box" }
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_segment_button = _resolveComponent("ion-segment-button")!
  const _component_ion_segment = _resolveComponent("ion-segment")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_player_item_vue = _resolveComponent("player-item-vue")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_item_option = _resolveComponent("ion-item-option")!
  const _component_ion_item_options = _resolveComponent("ion-item-options")!
  const _component_ion_item_sliding = _resolveComponent("ion-item-sliding")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_new_player_vue = _resolveComponent("new-player-vue")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_new_game_vue = _resolveComponent("new-game-vue")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, {
        id: "main-content",
        translucent: true
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_back_button, {
                    "default-href": "/home",
                    text: "accueil"
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Equipe")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("h2", null, _toDisplayString(_ctx.team.name), 1)
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("img", {
                    src: _ctx.team.logo
                  }, null, 8, _hoisted_6)
                ])
              ])
            ])
          ]),
          _createVNode(_component_ion_segment, {
            modelValue: _ctx.page,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.page) = $event)),
            onIonChange: _ctx.segmentChanged,
            style: {"background-color":"lightblue","margin-top":"2vh"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_segment_button, { value: "effectif" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Effectif")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_segment_button, { value: "match" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Match")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue", "onIonChange"]),
          _withDirectives(_createElementVNode("div", null, [
            _createVNode(_component_ion_button, { id: "new-player" }, {
              default: _withCtx(() => [
                _createTextVNode("nouveau")
              ]),
              _: 1
            })
          ], 512), [
            [_vShow, _ctx.page == 'effectif']
          ]),
          _withDirectives(_createElementVNode("div", null, [
            _createVNode(_component_ion_button, { id: "new-game" }, {
              default: _withCtx(() => [
                _createTextVNode("Nouveau")
              ]),
              _: 1
            })
          ], 512), [
            [_vShow, _ctx.page == 'match']
          ]),
          (_ctx.page == 'effectif')
            ? (_openBlock(), _createBlock(_component_ion_list, { key: 0 }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.team.players, (player) => {
                    return (_openBlock(), _createBlock(_component_ion_item_sliding, {
                      key: player.id
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_player_item_vue, { player: player }, null, 8, ["player"]),
                        _createVNode(_component_ion_item_options, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_item_option, { color: "primary" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_button, {
                                  id: "edit-player",
                                  onClick: ($event: any) => (_ctx.currentPlayer = player)
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_icon, { icon: _ctx.pencilOutline }, null, 8, ["icon"])
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"])
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_item_option, {
                              color: "danger",
                              onClick: ($event: any) => (_ctx.deletePlayer(player))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, { icon: _ctx.trashOutline }, null, 8, ["icon"])
                              ]),
                              _: 2
                            }, 1032, ["onClick"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.page == 'match')
            ? (_openBlock(), _createBlock(_component_ion_list, { key: 1 }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.games, (game) => {
                    return (_openBlock(), _createBlock(_component_ion_item_sliding, {
                      key: game.id
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, {
                          "router-link": '/match/' + game.id
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(game?.team1.name) + " - " + _toDisplayString(game?.team2.name), 1)
                          ]),
                          _: 2
                        }, 1032, ["router-link"]),
                        _createVNode(_component_ion_item_options, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_item_option, {
                              onClick: ($event: any) => (_ctx.exportStat(game.stats))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, { icon: _ctx.downloadOutline }, null, 8, ["icon"])
                              ]),
                              _: 2
                            }, 1032, ["onClick"]),
                            _createVNode(_component_ion_item_option, {
                              color: "danger",
                              onClick: ($event: any) => (_ctx.deleteGame(game.id))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, { icon: _ctx.trashOutline }, null, 8, ["icon"])
                              ]),
                              _: 2
                            }, 1032, ["onClick"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_ion_modal, {
            ref: "new-player",
            trigger: "new-player"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_new_player_vue, { team: _ctx.team }, null, 8, ["team"])
            ]),
            _: 1
          }, 512),
          _createVNode(_component_ion_modal, {
            ref: "new-player",
            trigger: "new-player"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_new_player_vue, {
                team: _ctx.team,
                playerProps: _ctx.currentPlayer
              }, null, 8, ["team", "playerProps"])
            ]),
            _: 1
          }, 512),
          _createVNode(_component_ion_modal, {
            ref: "new-game",
            trigger: "new-game",
            onWillDismiss: _ctx.onWillDismiss
          }, {
            default: _withCtx(() => [
              _createVNode(_component_new_game_vue, { team: _ctx.team }, null, 8, ["team"])
            ]),
            _: 1
          }, 8, ["onWillDismiss"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}