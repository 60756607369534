
//import BasketballField from "@/components/BasketballField.vue";
import SubVue from "@/components/Sub.vue";
import { star, returnUpBackOutline, reloadOutline } from "ionicons/icons";
import { defineComponent, ref, watchEffect } from "vue";
import storage from "@/storage";
import Youtube from "vue3-youtube"
import {
  IonPage,
  IonCol,
  IonRow,
  IonText,
  IonTextarea,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButton,
  IonModal,
  IonIcon,
  IonContent,
  IonThumbnail,
} from "@ionic/vue";
import { Game } from "@/models/game";
import { Player } from "@/models/player";
import { Stat } from "@/models/stat";
import VideoPlayer from "@/components/VideoPlayer.vue";
import { useMeta } from "vue-meta";
import { getGame, saveGame } from "@/service/game";
import NavbarMatch from "@/components/NavbarMatch.vue";

export default defineComponent({
  name: "MatchPage",
  data: () => ({
    game: {} as Game,
    time: 0,
    timer: {} as any,
    runningTimer: false,
    orientationlandscape: false,
    currentPlayer: {} as Player,
    currentNote: "",
    videoplayer: {} as any,
    windowWith: window.innerWidth,
  }),
  components: {
    Youtube,
    IonPage,
    IonModal,
    IonCol,
    IonRow,
    IonText,
    IonTextarea,
    SubVue,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonButton,
    //BasketballField,
    IonThumbnail,
    VideoPlayer,
    NavbarMatch,
    //IonMenuToggle,
    //IonIcon,
  },
  async mounted() {
    const gameid = this.$route.params["id"] as string;
    this.game = await getGame(gameid);
    this.videoplayer = this.game.src.includes('youtu') ? (this.$refs.youtube as any) :  (this.$refs.videoplayer as any);
    console.log(this.game);
    //this.handleOrientationChange();
  },
  setup() {
    useMeta({
      title: "Match",
    });
    return {
      star,
      returnUpBackOutline,
      reloadOutline,
    };
  },
  methods: {
    handleOrientationChange() {
      const orientation = window.screen.orientation.type;
      if (orientation === "portrait-primary") {
        // portrait mode
        this.orientationlandscape = false;
      } else if (orientation === "landscape-primary") {
        // landscape mode
        this.orientationlandscape = true;
      }
    },
    onReady() {
      (this.$refs.youtube as any).seekTo(
        Math.max(...this.game.stats.map((s) => s.time))
      );
      (this.$refs.youtube as any).playVideo();
    },
    historiqueClick(ev: Event) {
      (this.$refs.basketField as any).addPoint(ev);
    },
    pause() {
      this.videoplayer.pause();
    },
    play() {
      this.videoplayer.play();
    },
    dismissNote() {
      (this.$refs.modal_note as any).$el.dismiss();
    },
    setNote() {
      const action = new Stat(
        this.currentPlayer,
        1,
        "note",
        this.game.src ? this.videoplayer.getCurrentTime() : this.time,
        this.currentNote
      );
      this.game.stats.unshift(action);
      if (this.game.stats.length % 3 == 0) {
        saveGame(this.game);
      }
      this.currentNote = "";
      (this.$refs.modal_note as any).$el.dismiss();
    },
    sethighlight() {
      const action = new Stat(
        this.currentPlayer,
        1,
        "pts",
        this.game.src ? this.videoplayer.getCurrentTime() : this.time
      );
      this.game.stats.unshift(action);
      if (this.game.stats.length % 2 == 0) {
        saveGame(this.game);
      }
    },
    displayTime(time: number) {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time - minutes * 60);

      return (
        this.str_pad_left(minutes, "0", 2) +
        ":" +
        this.str_pad_left(seconds, "0", 2)
      );
    },
    str_pad_left(string: number, pad: string, length: number) {
      return (new Array(length + 1).join(pad) + string).slice(-length);
    },
    handleTimer() {
      if (this.runningTimer) {
        clearInterval(this.timer);
        this.runningTimer = false;
      } else {
        this.timer = setInterval(this.startTimer, 1000);
        this.runningTimer = true;
      }
    },
    startTimer() {
      this.time++;
    },
    cancelAction() {
      this.game.stats.shift();
      saveGame(this.game);
    },
    async onWillDismiss() {
      saveGame(this.game);
    },
    setAction(nom: string, num: number, position: { x: number, y: number} = {x: 0, y: 0}) {
      if (!this.currentPlayer.name) return;
      const action = new Stat(
        this.currentPlayer,
        num,
        nom,
        this.game.src ? this.videoplayer.getCurrentTime() : this.time,
        "",
        position
      );
      this.game.stats.unshift(action);
      this.currentPlayer = {} as Player;
      if (this.game.stats.length % 3 == 0) {
        saveGame(this.game);
      }
    },
  },
});
