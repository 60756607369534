
import { defineComponent } from "vue";
import {
  IonContent,
  IonButton,
  IonItem,
  IonList,
  IonLabel,
  IonInput,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonButtons,
  modalController,
} from "@ionic/vue";

import { checkmarkOutline, closeOutline } from "ionicons/icons";
import { v4 as uuidv4 } from "uuid";
import storage from "@/storage";
import { Team } from "@/models/team";
import { Player } from "@/models/player";

export default defineComponent({
  name: "NewPlayerVue",
  props: {
    team: Team,
    playerProps: Player,
  },
  data: () => ({ player: {} as Player }),
  components: {
    IonContent,
    IonButton,
    IonItem,
    IonList,
    IonLabel,
    IonInput,
    IonIcon,
    IonHeader,
    IonToolbar,
    IonButtons,
  },
  mounted() {
    this.player = { ...this.playerProps } as Player;
  },
  methods: {
    getBase64: (file: File) =>
      new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result?.toString() || "");
        reader.onerror = (error) => reject(error);
      }),
    changeImg(evt: Event) {
      const target = evt.target as HTMLInputElement;
      const file = target.files;
      if (file) {
        this.getBase64(file[0]).then((res) => (this.player.picture = res));
      }
    },
    cancel() {
      return modalController.dismiss(null, "cancel");
    },

    async savePlayer() {
      let teams = (await storage.get("teams")) as Team[];
      const team = this.team;
      this.player.id = uuidv4();
      team?.players.push(this.player);

      teams[teams.map((t) => t.id).indexOf(this.team?.id ?? "-1")] =
        team ?? ({} as Team);

      await storage.set("teams", JSON.parse(JSON.stringify(teams)));

      modalController.dismiss(null, "saved");
    },
  },
  setup() {
    return {
      checkmarkOutline,
      closeOutline,
    };
  },
});
