
import { Player } from "@/models/player";
import { defineComponent } from "vue";
import { IonAvatar, IonItem, IonCol, IonRow, IonLabel } from "@ionic/vue";

import { closeOutline } from "ionicons/icons";

export default defineComponent({
  name: "PlayerItem",
  components: {
    IonAvatar,
    IonItem,
    IonCol,
    IonRow,
    IonLabel,
  },
  props: {
    player: Object,
  },
  setup() {
    return {
      closeOutline,
    };
  },
});
